import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {GetUserRoutersApi} from '../api/api'
// import home from '@/views/Home/index.vue'
 
Vue.use(VueRouter)
 
//3.创建路由的实例对象
const router = new VueRouter({
    //白名单
    mode:'hash',
    routes: [
        {
            path: '/',
            name:'mainlayout',
            component: () => import('../views/Layout/MianLayout.vue'),
            redirect:"/home",
            // children:[
            //     {
            //       path:"/home",
            //       meta:{titles:["首页"]},
            //       component: () => import(/* webpackChunkName: "home" */ '../views/Home/index.vue')
            //     },
                // {
                //   path:"/customer/customer"
                //   component: () => import(/* webpackChunkName: "customer" */ '../views/customer/Customer.vue')
                // },
                // {
                //   path:"/customer/visit",
                //   component: () => import(/* webpackChunkName: "visit" */ '../views/customer/Visit.vue')
                // },
                // {
                //   path:"/flow/definition",
                //   component: () => import(/* webpackChunkName: "definition" */ '../views/flow/Definition.vue')
                // }
            //   ]
        },{
            path: '/login',
            name:'login',
            component: () => import('../views/Login/Login.vue')
        },{
            path:'*',
            component: () => import('../views/404/index.vue')
        },{
            path: '/IM_Message',
            name: 'IM_Message',
            component: () => import('../views/IM_Message/index.vue')
        }
    ]
});

router.beforeEach(async(to,from,next)=>{
    const whiteList = ['/IM_Message'];
    if (whiteList.includes(to.path)) {  
        // 如果目标路由在白名单中，直接跳转  
        next();  
    }else{
        const token = localStorage.getItem("A___ling-Authorization-token");
        const uids = localStorage.getItem("A___ling-uid");
        if(to.path=="/login" && token){
            next('/');
            return
        }
        if(to.path!=="/login" && !token){
            next('/login');
            return
        }
        
        // debugger
        if(token && store.state.userMenuData.menuData.length==0){
            let param = {
                uid: uids
            }
            let GetUserRoutersApiRes = await  GetUserRoutersApi(param);
            if(!GetUserRoutersApiRes)return;
            // let newUserMenuData = [{title: "系统首页",path:"/",icon: "http://39.107.88.107:8089/Files/首页.png",}]
            let newUserMenuData = [];
            let ret = GetUserRoutersApiRes.data?.map(item=>{
                if(!item)return null;
                if(item.children){
                return {
                    title: item.meta.title,
                    icon: item.meta.icon,
                    path: item.path,
                    children: item.children.map(sitem=>{
                        return{
                        title:sitem.meta.title,
                        path:item.path+"/"+sitem.path,
                        icon:sitem.icon
                        }
                    })
                }  
                }else{
                return {
                    title: item.meta.title,
                    path: item.path,
                    icon: item.meta.icon,
                }
                }
                
            })||[];
            newUserMenuData = [...newUserMenuData,...ret];
            store.commit("userMenuData/changeMenuData",newUserMenuData)
            let newChildrenRoutes=[{
                path:"/home",
                meta:{titles:["首页"]},
                // icon: "http://39.107.88.107:8089/Files/首页.png",
                component: () => import('../views/Home/index.vue')
            }]
            if (GetUserRoutersApiRes.data) {
                GetUserRoutersApiRes.data.forEach(item => {
                    let ret = item.children?.map(sitem => {
                        return {
                            path: item.path + "/" + sitem.path,
                            component: () => import(`../views${item.path}/${sitem.path}.vue`),
                            meta: {
                                titles: [item.meta.title, sitem.meta.title]
                            }
                        }
                    })
                    newChildrenRoutes = [...newChildrenRoutes, ...ret]
                })
            }
            // console.log(newChildrenRoutes);
            // 遍历新的子路由，如果当前路由与子路由的路径相同，则跳转到通配符路由，否则将子路由添加到主路由中。
            newChildrenRoutes.forEach(item=>{
                if(to.path == item.path){
                    next("*");
                }
                router.addRoute("mainlayout",item);
            });
            next(to.path);
            return
        }
    }
    
    next();
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => {})
};
export default router

