<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
    
  },
  beforeDestroy() {
    
  },
  mounted(){
    // window.onbeforeunload = function (e) {
    //             var storage = window.localStorage;
    //             storage.clear()
    //         }
  }
}
</script>

<style>
html,body,#app {
  width: 100%;
  height: 100%;
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px; */
}
.textC {
    text-align: center;
}
</style>
