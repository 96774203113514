import router from "@/router";
import Vue from "vue"
import axios from "axios"
import { Message} from 'element-ui'
const instance = axios.create({
    // baseURL: "http://localhost:5000",
    // baseURL: "http://39.107.88.107:8089",
    baseURL: "https://simadongxiqilimi.xyz:8089",
    timeout: 1200000
})

instance.interceptors.request.use(config=>{
    const token = localStorage.getItem("A___ling-Authorization-token")
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    if(token && !config.url.endsWith("/login") && !config.url.endsWith("/captchaImage") ){
        config.headers["Authorization"] = token
    }
    return config;
},err=>{
    return Promise.reject(err);
});

instance.interceptors.response.use(res=>{
    // console.log(JSON.stringify(res))
    let res_data = res.data;
    if(res_data.code!=500){
        if(res_data.code == 401 || res_data.code == 402){
            Message({
                message: res_data.msg || '异常！',
                type: 'error',
                duration: 3 * 1000
            })
            localStorage.clear();
        }
    }else{
        Message({
            message: res_data.msg || '异常！',
            type: 'error',
            duration: 3 * 1000
        })
    }
    

    return res.data;
},err=>{
    if(!err.response){
        Message({
            message: '网络异常！',
            type: 'error',
            duration: 3 * 1000
        })
    }
    if(err.response.status == 500 || err.response.status == null){
        Message({
            message: '服务端异常！',
            type: 'error',
            duration: 3 * 1000
        })
        localStorage.clear();
        router.push("/login")
    }
    if(err.response.status == 404){
        Message({
            message: '客户端异常！',
            type: 'error',
            duration: 3 * 1000
        })
    }
    
    // router.push("/login")
    
    // return Promise.reject(err);
});

export default instance;