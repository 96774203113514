import instance from "../utils/request"
//获取验证码
export const GetCaptchaCodeApi = ()=> instance.get("/prod-api/captchaImage");

//登录
export const LoginApi = (params) => instance.post("/Permission/login",params);

// 获取用户可以访问的路由的api
export const GetUserRoutersApi = (getMenuListParams) => instance.post("/Permission/getMenuList",getMenuListParams);

// 获取用户信息
export const GetUserInfoApi = (UserInfoParams) => instance.post("/Permission/UserInfo",UserInfoParams);

//获取用户列表
export const GetUserListApi = (params) => instance.post("/User/GetUserList",params);

//添加用户
export const addUser = (params) => instance.post("/User/addUser",params);

//编辑用户
export const editUser = (params) => instance.post("/User/editUser",params);
//删除用户
export const deleteUser = (params) => instance.post("/User/DelUserByID",params);

//获取菜单列表
export const getMenuLists = (params) => instance.post("/Permission/GetMenuLists",params);

//父级菜单下拉框
export const GetMenuOptions = (params) => instance.post("/Permission/GetMenuOptions",params);

//添加菜单
export const AddMenu = (params) => instance.post("/Permission/AddMenu",params);

//删除菜单
export const DeleteMenu = (params) => instance.post("/Permission/DeleteMenu",params);

//编辑菜单下拉框
export const EditMenuOptions = (params) => instance.post("/Permission/EditMenuOptions",params);

//编辑菜单
export const EditMenu = (params) => instance.post("/Permission/EditMenu",params);

//获取角色列表
export const GetRoleList = (params) => instance.post("/SysRole/GetRoleList",params);

//角色下拉框
export const GetRoleOptions = (params) => instance.post("/SysRole/GetRoleListByUid",params);

//添加角色信息
export const AddRole = (params) => instance.post("/SysRole/AddRole",params);

//修改角色信息
export const EditRole = (params) => instance.post("/SysRole/EditRole",params);

//DelRoleByID
export const DelRoleByID = (params) => instance.post("/SysRole/DelRoleByID",params);

//控制器方法下拉框
export const GetRoleControllerActionList = (params) => instance.post("/Permission/GetRoleControllerActionList",params);

//获取菜单下拉
export const GetMenuListsRole = (params) => instance.post("/Permission/GetMenuListsRole",params);

//添加公司
export const AddCompany = (params) => instance.post("/Permission/AddCompany",params);

//获取公司列表
export const GetCompanyList = (params) => instance.post("/Permission/GetCompanyList",params);

//编辑公司信息
export const EditCompany = (params) => instance.post("/Permission/EditCompany",params);

//删除公司
export const DelCompanyByID = (params) => instance.post("/Permission/DelCompanyByID",params);

//删除公司
export const GetCompanySelectID = (params) => instance.post("/Permission/GetCompanySelectID",params);

//获取Ip
export const GetIp = (params) => instance.get("/Permission/GetIp",params);
