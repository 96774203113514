import {GetUserInfoApi} from "../../api/api"
export default{
    namespaced:true,
    state:{
       userInfo:JSON.parse(localStorage.getItem("A___ling-userInfo")) || {
        uid:null,
        name:null,
        avatar:null,
        roles:null
       }
    },
    mutations:{
        changeUserInfo(state,payload){
            state.userInfo = payload;
            // console.log(state.userInfo);
            localStorage.setItem("A___ling-userInfo", JSON.stringify(state.userInfo))
        },
        removeUserInfo(state,payload){
            state.userInfo=null
        }
    },
    actions:{
        async asyncChangeUserInfo({commit},payload){
            const uids = localStorage.getItem("A___ling-uid");
            // console.log(uids);
            let GetUserInfoApiRes = await GetUserInfoApi({uid: uids});
            // console.log(GetUserInfoApiRes);
            // debugger
            if(!GetUserInfoApiRes)return;
            commit("changeUserInfo",{
                uid:GetUserInfoApiRes.data.id,
                name:GetUserInfoApiRes.data.name,
                avatar:GetUserInfoApiRes.data.avatar,
                // roles:GetUserInfoApiRes.data.roles
            })
        }
    }
}