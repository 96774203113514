import Vue from 'vue'

import 'reset-css'
import App from './App.vue'
import './plugins/element.js'

import router from '@/router'
import store from './store'
import qs from 'qs'

import VueParticles from 'vue-particles'
Vue.use(VueParticles)


Vue.config.productionTip = false
Vue.prototype.$qs = qs
new Vue({
  
  render: h => h(App),
  router,
  store,
}).$mount('#app')
