import Vue from 'vue'
import { Button,Form,FormItem,Input,Message,Menu,Submenu,
    MenuItemGroup,MenuItem,BreadcrumbItem,Breadcrumb,Dropdown,
    DropdownMenu, DropdownItem,Tag,TableColumn,Table,Pagination,
    Dialog,Select,Option,MessageBox,Tree,Loading,RadioGroup,Radio,Container,Main,Upload} from 'element-ui'
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(BreadcrumbItem)
Vue.use(Breadcrumb)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tag)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tree)
Vue.use(Loading)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Container)
Vue.use(Main)
Vue.use(Upload)

